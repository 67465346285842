import { Route, Switch, useLocation } from "./../util/router";
import NotFoundPage from "./404";
import IndexPage from "./index";
import Times from "./times";
import Meta from "../components/Meta";
import BlogPost from "./BlogPost";
import BlogList from "./BlogList";
import PrivacyPolicy from "./privacy";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function AppRoutes() {
    const location = useLocation();
  
    return (
        <>
            <Meta />
                <TransitionGroup>
                <CSSTransition key={location.key} classNames="fade" timeout={300}>
                    <Switch>
                        <Route exact path="/" component={IndexPage} />

                        <Route exact path="/teetimes" component={Times} />

                        <Route path="/post/:postName" component={BlogPost} />

                        <Route path="/blog" component={BlogList} />

                        <Route exact path="/privacy" component={PrivacyPolicy} />

                        <Route component={NotFoundPage} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
}

export {AppRoutes};