import React from "react";
import TeeTimeList from "../components/TeeTimeList";
import { ACTIONS, useDispatchPage, usePage } from "../context/context";
import { useHistory, useLocation } from "../util/router";
import useGetData from "../util/useGetData.ts";
import NoCityState from "../components/NoCityState";
import NewsletterSection from "../components/NewsletterSection";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import golf from "./golf.webp";
// import { ChevronUpIcon } from "@heroicons/react/20/solid";
// import { Disclosure } from "@headlessui/react";
import { getImageUrl } from "../util/util.js";

const pageMeta = {
  title: "Book Your Next Tee Time - Tee Time Wizard",
  description:
    "Easily find and book tee times in your favorite cities. Discover the best golf courses and book your slot today!",
  image: getImageUrl(golf),
};

function Times(props) {
  const state = usePage();
  const dispatch = useDispatchPage();
  const history = useHistory();
  const location = useLocation();
  const { data, isLoading, status } = useGetData();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const dateParam = queryParams.get("date");
    const timeParam = queryParams.get("time");
    const cityParam = queryParams.get("city");
    const courseParam = queryParams.get("course");
    const playersParam = queryParams.get("players");

    if (dateParam !== null) {
      dispatch({ type: ACTIONS.SET_DATE, payload: dateParam });
    }

    if (timeParam !== null) {
      dispatch({ type: ACTIONS.SET_TIME, payload: timeParam });
    }

    if (cityParam !== null) {
      dispatch({ type: ACTIONS.SET_CITY, payload: cityParam });
    }

    if (courseParam !== null) {
      dispatch({ type: ACTIONS.SET_COURSE, payload: courseParam });
    }

    if (playersParam !== null) {
      dispatch({
        type: ACTIONS.SET_PLAYERS,
        payload: parseInt(playersParam, 10) || 1,
      });
    }
  }, [location.search, dispatch]);

  function getUniqueNames(data) {
    const uniqueNames = data.map((item) => item.name);
    return uniqueNames.filter((v, i, a) => a.indexOf(v) === i);
  }

  const courses = data ? getUniqueNames(data) : [];

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "date":
        dispatch({ type: ACTIONS.SET_DATE, payload: value });
        break;
      case "time":
        dispatch({ type: ACTIONS.SET_TIME, payload: value });
        break;
      case "city":
        dispatch({ type: ACTIONS.SET_CITY, payload: value });
        break;
      case "course":
        dispatch({ type: ACTIONS.SET_COURSE, payload: value });
        break;
      case "players":
        dispatch({ type: ACTIONS.SET_PLAYERS, payload: parseInt(value, 10) });
        break;
      default:
        break;
    }

    const newQueryParams = new URLSearchParams(location.search);
    newQueryParams.set(name, value);
    history.push({
      pathname: location.pathname,
      search: newQueryParams.toString(),
    });
  };

  const inputStyles =
    "mt-2 block w-full h-8 rounded-md border-0 py-1.5 pl-3 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6";

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col">
      <Meta {...pageMeta} />
      <Navbar />
      <div className="flex-grow flex">
      {state.city === "" ? (
        // No city selected
        <div className="flex flex-col items-center justify-center bg-gray-900 mt-10">
          <h1 className="text-4xl font-bold tracking-tight text-gray-100 sm:text-6xl">
            Teetime Wizard
          </h1>
          <NoCityState />
          <NewsletterSection />
        </div>
      ) : isLoading ? (
        // Loading spinner
        <div className="flex justify-center items-center w-full mt-[-130px]">
          <div className="border-t-4 border-blue-500 border-solid w-16 h-16 rounded-full animate-spin"></div>
        </div>
      ) : status === "error" ? (
        // Error message
        <div className="flex justify-center items-center text-gray-400 w-full mt-[-130px] text-gray-300">
          <div>Something went wrong. Please try again later.</div>
        </div>
      ) : (
        // Main content when data is loaded
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 sm:py-6 lg:py-8 w-full px-4">
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg shadow">
            <div className="overflow-hidden bg-gray-50 sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="px-1 grid grid-cols-1 lg:gap-6 lg:grid-cols-5">
                  {/* Date Filter */}
                  <div className="lg:col-span-1">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      value={state.date}
                      onChange={handleInputChange}
                      className={inputStyles}
                    />
                  </div>

                  {/* Players Filter */}
                  <div className="lg:col-span-1">
                    <label
                      htmlFor="players"
                      className="block text-sm font-medium leading-6 text-gray-900 pt-2 lg:pt-0"
                    >
                      Players
                    </label>
                    <select
                      name="players"
                      id="players"
                      value={state.players}
                      onChange={handleInputChange}
                      className={inputStyles}
                    >
                      <option value="1">1 player</option>
                      <option value="2">2 players</option>
                      <option value="3">3 players</option>
                      <option value="4">4 players</option>
                    </select>
                  </div>

                  {/* Accordion for Other Filters on Mobile
                  <div className="lg:hidden lg:col-span-3">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-900 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                            <span>More Filters</span>
                            <ChevronUpIcon
                              className={`${
                                open ? "transform rotate-180" : ""
                              } h-5 w-5 text-indigo-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pb-2 text-sm text-gray-500">
                            <div>
                              <label
                                htmlFor="time"
                                className="pt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Time
                              </label>
                              <select
                                name="time"
                                id="time"
                                value={state.time}
                                onChange={handleInputChange}
                                className={inputStyles}
                              >
                                <option value="5:00am">5:00am</option>
                                <option value="6:00am">6:00am</option>
                                <option value="7:00am">7:00am</option>
                                <option value="8:00am">8:00am</option>
                                <option value="9:00am">9:00am</option>
                                <option value="10:00am">10:00am</option>
                                <option value="11:00am">11:00am</option>
                                <option value="12:00pm">12:00pm</option>
                                <option value="1:00pm">1:00pm</option>
                                <option value="2:00pm">2:00pm</option>
                                <option value="3:00pm">3:00pm</option>
                                <option value="4:00pm">4:00pm</option>
                                <option value="5:00pm">5:00pm</option>
                                <option value="6:00pm">6:00pm</option>
                                <option value="7:00pm">7:00pm</option>
                                <option value="8:00pm">8:00pm</option>
                              </select>
                            </div>

                            <div>
                              <label
                                htmlFor="city"
                                className="pt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                City
                              </label>
                              <select
                                name="city"
                                id="city"
                                value={state.city}
                                onChange={handleInputChange}
                                className={inputStyles}
                              >
                                <option value="">Select city</option>
                                <option value="Sydney">Sydney</option>
                                <option value="Melbourne">Melbourne</option>
                                <option value="Brisbane">Brisbane</option>
                                <option value="Perth">Perth</option>
                              </select>
                            </div>

                            <div>
                              <label
                                htmlFor="course"
                                className="pt-2 block text-sm font-medium leading-6 text-gray-900"
                              >
                                Course
                              </label>
                              <select
                                name="course"
                                id="course"
                                value={state.course}
                                onChange={handleInputChange}
                                className={inputStyles}
                              >
                                <option value="All">Select course</option>
                                {courses.map((course, index) => (
                                  <option key={index} value={course}>
                                    {course}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>  */}

                  <div className="lg:grid lg:col-span-3 lg:grid-cols-3 lg:gap-6">
                    {/* Time Filter */}
                    <div className="lg:col-span-1">
                      <label
                        htmlFor="time"
                        className="block text-sm font-medium leading-6 text-gray-900 pt-2 lg:pt-0 "
                      >
                        Time
                      </label>
                      <select
                        name="time"
                        id="time"
                        value={state.time}
                        onChange={handleInputChange}
                        className={inputStyles}
                      >
                        <option value="5:00am">5:00am</option>
                        <option value="6:00am">6:00am</option>
                        <option value="7:00am">7:00am</option>
                        <option value="8:00am">8:00am</option>
                        <option value="9:00am">9:00am</option>
                        <option value="10:00am">10:00am</option>
                        <option value="11:00am">11:00am</option>
                        <option value="12:00pm">12:00pm</option>
                        <option value="1:00pm">1:00pm</option>
                        <option value="2:00pm">2:00pm</option>
                        <option value="3:00pm">3:00pm</option>
                        <option value="4:00pm">4:00pm</option>
                        <option value="5:00pm">5:00pm</option>
                        <option value="6:00pm">6:00pm</option>
                        <option value="7:00pm">7:00pm</option>
                        <option value="8:00pm">8:00pm</option>
                      </select>
                    </div>

                    {/* Course Filter */}
                    <div className="lg:col-span-1">
                      <label
                        htmlFor="course"
                        className="block text-sm font-medium leading-6 text-gray-900 pt-2 lg:pt-0"
                      >
                        Course
                      </label>
                      <select
                        name="course"
                        id="course"
                        value={state.course}
                        onChange={handleInputChange}
                        className={inputStyles}
                      >
                        <option value="All">Select course</option>
                        {courses.map((course, index) => (
                          <option key={index} value={course}>
                            {course}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* City Filter */}
                    <div className="hidden sm:block lg:col-span-1">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium leading-6 text-gray-900 pt-2 lg:pt-0"
                      >
                        City
                      </label>
                      <select
                        name="city"
                        id="city"
                        value={state.city}
                        onChange={handleInputChange}
                        className={inputStyles}
                      >
                        <option value="">Select city</option>
                        <option value="Sydney">Sydney</option>
                        <option value="Melbourne">Melbourne</option>
                        <option value="Brisbane">Brisbane</option>
                        <option value="Perth">Perth</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Tee Time List */}
              <TeeTimeList />
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
}

export default Times;
