import {
    QueryClient,
    QueryClientProvider as QueryClientProviderBase,
} from "react-query";

const client = new QueryClient();

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
    return (
      <QueryClientProviderBase client={client}>
        {props.children}
      </QueryClientProviderBase>
    );
}
