import { useRef, useEffect } from "react";

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

// handle image paths dynamicaly to fix capacitor image issue
export const getImageUrl = (imagePath) => {
  if (window.Capacitor !== undefined) {
    const baseUrl = 'https://teetimewizard.com';
    return `${baseUrl}/${imagePath}`;
  } else {
    return imagePath;
  }
};
