import React from "react";
import { Link } from "./../util/router";

import golf from "./golf.webp";
import { getImageUrl } from "../util/util";

function Navbar(props) {
  const golfUrl = getImageUrl(golf)
  return (
    <header className="pt-5 -mb-6 sm:pb-5 px-4 z-10">
      <div className="container flex flex-col flex-wrap items-center mx-auto md:flex-row max-w-5xl sm:px-6 lg:px-8">
        <Link to="/" className="-m-1.5 p-1.5">
          <h1 className="sr-only">Teetime Wizard</h1>
          <img className="h-16 sm:h-12 px-2 w-auto" src={golfUrl} alt="" />
        </Link>
        <Link
          to="/"
          className="mb-3 md:mb-0 text-4xl font-bold text-white pacificoFont hidden sm:block z-10"
        >
          Teetime Wizard
        </Link>

        {/* <nav className="flex flex-wrap items-center md:ml-auto">
          <Link to="/about" className="ml-5">
            About
          </Link>

          {(!auth.user || !auth.user.stripeSubscriptionId) && (
            <Link to="/pricing" className="ml-5">
              Pricing
            </Link>
          )}

          {auth.user && (
            <>
              <Link to="/dashboard" className="ml-5">
                Dashboard
              </Link>
              <Link to="/settings/general" className="ml-5">
                Settings
              </Link>
              <Link
                to="/auth/signout"
                className="ml-5"
                onClick={(e) => {
                  e.preventDefault();
                  auth.signout();
                }}
              >
                Sign out
              </Link>
            </>
          )}

          {!auth.user && (
            <Link to="/auth/signin" className="ml-5">
              Sign in
            </Link>
          )}
        </nav> */}
      </div>
    </header>
  );
}

export default Navbar;
