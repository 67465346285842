import React, { useEffect } from "react";
import { PageProvider } from "../context/context";
import "./../styles/global.css";
import { createUserMetrics } from "metric-shack";
import { Router } from "./../util/router";
import { AppRoutes } from "./AppRoutes";
import { QueryClientProvider } from "../util/queryClientProvider";

function App(props) {
  createUserMetrics("f2c882db-23a0-4723-a73c-7dcfe3740aa4");

  useEffect(() => {
    if (window.Capacitor !== undefined) {
      setTimeout(() => {
        window.Capacitor.Plugins.SplashScreen.hide()
      }, 2000)
    }
  }, [])
  
  return (
    <QueryClientProvider>
        <PageProvider>
          <Router>
            <AppRoutes />
          </Router>
        </PageProvider>
    </QueryClientProvider>
  );
}

export default App;
