import React from "react";
import Meta from "./../components/Meta";
import Navbar from "./../components/Navbar";
function PrivacyPolicy(props) {
  return (
    <>
      <Meta title="Privacy Policy" description="Teetime Wizard Privacy Policy" />
      <Navbar />
      <section className="pt-12 pb-4 px-4">
        <div className="container mx-auto text-gray-200 ">
          <h1 className="text-2xl font-bold mb-4">Teetime Wizard Privacy Policy</h1>
          <p className="mb-4"><strong>Effective: </strong>27/9/2024</p>

          <p className="mb-4">
            <strong>Introduction</strong>
            <br />
            Teetime Wizard is committed to protecting your privacy. This privacy policy explains how we handle your information when you use our app. By using our app, you agree to the collection and use of information in accordance with this policy.
          </p>

          <p className="mb-4">
            <strong>Information We Collect</strong>
          </p>

          <p className="mb-4">
            1. <strong>Anonymised Analytics</strong>
            <br />
            We collect basic anonymised analytics data to improve the functionality and user experience of the app. This information does not identify you personally and is only used to help us understand general app usage patterns.
          </p>

          <p className="mb-4">
            2. <strong>Location Information</strong>
            <br />
            Our app requires access to your device's GPS to determine the city you are in. This information is only used for the functionality of the app and is neither stored nor shared with third parties.
          </p>

          <p className="mb-4">
            <strong>No User Accounts or Personal Information</strong>
            <br />
            Our app does not require you to create a user account, nor does it collect or store any personally identifiable information (PII).
          </p>

          <p className="mb-4">
            <strong>How We Use Your Information</strong>
            <br />
            The anonymised analytics data is used to:
            <ul className="list-disc list-inside">
              <li>Improve app performance</li>
              <li>Identify and fix bugs</li>
              <li>Optimise user experience</li>
            </ul>
            Location data is used solely to provide app functionality related to determining your city.
          </p>

          <p className="mb-4">
            <strong>Third-Party Services</strong>
            <br />
            We do not share any personal data with third-party services.
          </p>

          <p className="mb-4">
            <strong>Security</strong>
            <br />
            We take reasonable steps to protect the anonymised data we collect. However, no method of transmission over the internet or method of electronic storage is 100% secure.
          </p>

          <p className="mb-4">
            <strong>Contact Us</strong>
            <br />
            If you have any questions or concerns about this privacy policy, please contact our privacy officer.
            <br />
            Email: <a href='mailto:contact@anyway.software' style={{textDecoration: 'underline'}}>contact@anyway.software</a>
          </p>

          <p className="mb-4">
            <strong>Changes to This Privacy Policy</strong>
            <br />
            We may update our privacy policy from time to time. We encourage you to review this policy periodically for any changes.
          </p>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
