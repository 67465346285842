import { Link } from "../util/router";

export default function Footer() {

  return (
    <section className="relative isolate overflow-hidden bg-gray-900 px-6 mt-6 text-gray-500">
        Copyright © 2024 - <Link to='/privacy' style={{textDecoration: 'underline'}}>Privacy Policy</Link>
    </section>
  );
}
